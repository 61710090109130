import { useMutation } from '@urql/vue';
import { createGhostStudentMutation } from '~/graphql/documents/core';

export const useGhostStudent = () => {
  const { fetchUser, isLoggedIn, isGhostStudent, globalStudentRole, globalTeacherRole, user } = useAuth();
  const { executeMutation: _createGhostStudent } = useMutation(createGhostStudentMutation);

  const createGhostStudent = async (firstName: string, lastName: string | undefined) => {
    try {
      const res = await _createGhostStudent({
        input: {
          firstName,
          lastName,
        },
      });

      if (res.error) {
        return false;
      }

      await fetchUser(true);

      return true;
    } catch (error) {
      return false;
    }
  };

  const canAuthedTeacherRegister = computed(
    () => isLoggedIn.value && !!globalTeacherRole.value && !globalStudentRole.value && user.value && user.value.userActivityGroups.length > 0,
  );

  return {
    isGhostStudent,
    isLoggedIn,
    createGhostStudent,
    canAuthedTeacherRegister,
  };
};
